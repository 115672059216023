import React from "react";

const pageStyle: React.CSSProperties = {
    maxWidth: '100%',
    fontSize: '12px',
    margin: '5%'
};

const bodyStyle: React.CSSProperties = {
    maxWidth: '100%',
    marginTop: '2%'
};

const headerstyle: React.CSSProperties = {
};

const sectionStyle: React.CSSProperties = {
    marginRight: '5%',
    textAlign: 'left',
};

const h2Style: React.CSSProperties = {
    fontSize: '12px',
    fontWeight: 'bold'
};

const headerImgStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
};

const hdStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    border: '.1px solid #D3D3D3',
    borderRadius: '5px',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
};

const pagesStyle: React.CSSProperties = {
    width: '96%',
    margin: '0% 2%',
    backgroundColor: 'white',
    textAlign: 'left',
};

const listStyle: React.CSSProperties = {
    marginLeft: '0',
    paddingLeft: '0',
    listStylePosition: 'inside',
};

const pageHeaderTableStyle: React.CSSProperties = {
    border: '1px solid black',
    width: '100%',
};

const pageHeaderTdStyle: React.CSSProperties = {
    border: '1px solid black',
    textAlign: 'center',
};

export { h2Style, sectionStyle, headerImgStyle, hdStyle, pagesStyle, listStyle, bodyStyle, pageHeaderTableStyle, pageHeaderTdStyle, pageStyle, headerstyle };