import { Button, Input, Select, Space } from 'antd'
import React, { useState } from 'react';
import { Option } from 'antd/es/mentions';
import { useNavigate } from 'react-router-dom';
const { REACT_APP_API_ENDPOINT } = process.env;

function FormGenerator() {

  const [title, setTitle] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [type, setType] = useState<number>(1);

  const sessionStorageUser = JSON.parse(localStorage.getItem('user') || '');
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    title: title,
    version: version,
    code: code,
    type: type,
    sections: []
  });

  const addSection = () => {
    setFormData({
        title: title,
        version: version,
        code: code,
        type: type,
        sections: [...formData.sections, {}]
      })
  }

  const removeSection = (index: number) => {
    formData.sections.splice(index, 1)
    setFormData({
      title: title,
      version: version,
      code: code,
      type: type,
      sections: formData.sections
    })
  }

  const generateJSON = (e: any) => {
    e.preventDefault();
    formData.title = title;
    formData.version = version;
    formData.code = code;
    formData.type = type;
    formData.sections = [...formData.sections];
    createFormTemplate();
  }

  const createFormTemplate = () => {
    if (formData.title == '') {
      alert('Ingrese un título')
      return
    }
    if (formData.version == '') {
      alert('Ingrese una versión')
      return
    }
    if (formData.code == '') {
      alert('Ingrese un código')
      return
    }
    fetch(`${REACT_APP_API_ENDPOINT}/form-templates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorageUser.token}`
      },
      body: JSON.stringify({
        title: formData.title,
        version: formData.version,
        code: formData.code,
        type: formData.type,
        form: {
            sections: formData.sections
        }
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        navigate('/forms')
      })
    }

  return (
        <div style={{textAlign: 'center'}}>
          <h1>Nuevo Formato</h1>
          <form onSubmit={generateJSON}>
            <div style={{width: '20%', marginLeft: '40%'}}>
              <label>Título:</label>
              <br></br>
              <i>Alfanumérico, de 3 a 100 caracteres de longitud</i>
              <Input
                  type="text"
                  name="title"
                  value={title}
                  pattern="[A-Za-z0-9 -_,.:;]{3,100}"
                  onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              <label>Versión:</label>
              <br></br>
              <i>Numérico, de 1 a 10 dígitos de longitud</i>
              <Input
                  type="text"
                  name="version"
                  value={version}
                  pattern="[0-9.,]{1,10}"
                  onChange={(e) => setVersion(e.target.value)}
              />
              <br />
              <label>Código:</label>
              <br></br>
              <i>Alfanumérico, de 3 a 30 dígitos de longitud</i>
              <Input
                  type="text"
                  name="code"
                  value={code}
                  pattern="[A-Za-z0-9 -_,.:;]{3,30}"
                  onChange={(e) => setCode(e.target.value)}
              />
              <br />
              <label>Tipo:</label>
              <Select
                  value={type}
                  onChange={(value) => setType(value)}
              >
                  <Option value="1">ISO</Option>
                  <Option value="2">SST</Option>
                  <Option value="3">Documentación</Option>
                  <Option value="4">Mapa de Procesos</Option>
                  <Option value="5">Políticas</Option>
                  <Option value="6">Normas/Leyes</Option>
                  <Option value="7">Matriz de Riesgos</Option>
                  <Option value="8">Auditoría</Option>
                  <Option value="9">Evaluación de Desempeño</Option>
              </Select>
              <br />
              <h2>Secciones</h2>
              {formData.sections.map((section: any, index: any) => (
                  <div key={index}>
                  <h3>Sección {index + 1}</h3>
                  <label>Id:</label>
                  <br></br>
                  <i>Alfanumérico sin espacios</i>
                  <Input
                      type="text"
                      value={section.id}
                      pattern="[A-z]{1}[A-Za-z0-9 -_,.:;]*"
                      onChange={(e: any) => section.id = e.target.value}
                  />
                  <br />
                  <label>Nombre:</label>
                  <br></br>
                  <i>Alfanumérico, de 3 a 30 dígitos de longitud</i>
                  <Input
                      type="text"
                      value={section.name}
                      pattern="[A-Za-z0-9 -_,.:;]{3,30}"
                      onChange={(e) => section.name = e.target.value}
                  />
                  <br />
                  <label>Botones:</label>
                  <br></br>
                  <i>Para ver una lista de los botones soportados, ver manual</i>
                  <Input
                      type="text"
                      value={section.buttons}
                      pattern="^(?:a|abbr|address|area|article|aside|audio|b|base|bdi|bdo|blockquote|body|br|button|canvas|caption|cite|code|col|colgroup|data|datalist|dd|del|details|dfn|dialog|div|dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|head|header|hr|html|i|iframe|img|input|ins|kbd|label|legend|li|link|main|map|mark|meta|meter|nav|noscript|object|ol|optgroup|option|output|p|param|picture|pre|progress|q|rb|rp|rt|rtc|ruby|s|samp|script|section|select|slot|small|source|span|strong|style|sub|summary|sup|table|tbody|td|template|textarea|tfoot|th|thead|time|title|tr|track|u|ul|var|video|wbr)(?:,(?:a|abbr|address|area|article|aside|audio|b|base|bdi|bdo|blockquote|body|br|button|canvas|caption|cite|code|col|colgroup|data|datalist|dd|del|details|dfn|dialog|div|dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|head|header|hr|html|i|iframe|img|input|ins|kbd|label|legend|li|link|main|map|mark|meta|meter|nav|noscript|object|ol|optgroup|option|output|p|param|picture|pre|progress|q|rb|rp|rt|rtc|ruby|s|samp|script|section|select|slot|small|source|span|strong|style|sub|summary|sup|table|tbody|td|template|textarea|tfoot|th|thead|time|title|tr|track|u|ul|var|video|wbr))*$"
                      onChange={(e) => section.buttons = e.currentTarget.value.split(',')}
                  />
                  <button onClick={() => {
                    removeSection(index);
                  }}>Borrar sección</button>
                  </div>
              ))}
              <Button onClick={addSection} type="default">Añadir sección</Button>
              <Button htmlType='submit' type="primary" style={{marginTop: '10px'}}>Guardar formulario</Button>
              </div>
            </form>
        </div>
      );
    }


export default FormGenerator;
